.responseContainer {
    width: 95%;
    overflow: hidden;
    margin: 20px 0 20px 5%;
    position: relative;
}

.commentText {
    font-family: 'Roboto', sans-serif;
    width: 70%;
    overflow: hidden;
    margin-left: 65px;
}

.commentAvatar {
    width: 45px;
    height: 45px;
    margin: 10px;
    float: left;
}

.commentAvatarImg {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.commentName {
    font-family: 'Roboto', sans-serif;
    float: left;
    width: 50%;
    margin-top: 20px;
    font-size: 16px;
}

.commentPublished {
    margin-top: 20px;
    color: #828282;
    font-family: 'Roboto', sans-serif;
    position: absolute;
    width: 22%;
    right: 0;
}

.commentImage {
	margin: 0;
	width: 100%;
}

.deleteCommentBtn {
    position: absolute !important;
    right: 0 !important;
    top: 35px !important;
    color: #828282 !important;
}

.deleteCommentBtn:hover {
    background-color: transparent !important;
}

.noUserContainer {
    text-align: center;
    margin: 10px;
    float: left;
}

.noUserIcon {
    width: 45px !important;
    height: 45px !important;
    color: #828282 !important;
}