.actionBtn {
    color: #828282 !important;
    margin-left: 10px !important;
    text-transform: none !important;
    margin: 4px 0!important;
    font-size: 14px !important;
    font-weight: normal !important;
    width: 165px;
}

.actionBtn:hover {
    background-color: transparent !important;
}

.actionIcon {
    width: 20px !important;
    height: 20px !important;
}

.actionText {
    margin-left: 10px;
    font-weight: lighter;
    position: relative;
    top: 1px;
}

.actionsContainer {
    border-top: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
    text-align: center;
}

.commentText {
    font-family: 'Roboto', sans-serif;
    width: calc(100% - 10px);
    overflow: hidden;
    margin-left: 10px;
    font-size: 16px;
}

.commentCondolenceContainer {
    width: 100%;
    overflow: hidden;
    margin: 20px 0;
    border-bottom: 10px solid #d8d8d8;
    padding-bottom: 10px;
    position: relative;
}

.commentAvatar {
    width: 45px;
    height: 45px;
    margin: 10px;
    float: left;
    margin-bottom: 40px;
}

.commentAvatarImg {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.commentName {
    font-family: 'Roboto', sans-serif;
    float: left;
    width: 50%;
    margin-top: 20px;
    font-size: 16px;
}

.commentPublished {
    color: #828282;
    font-family: 'Roboto', sans-serif;
    width: 22%;
    position: absolute;
    right: 5%;
    top: 50px;
}

.commentImage {
	margin: 0;
	width: 100%;
}

.commentVideo {
	margin: 0;
	height: 300px;
	width: 100%;
}

.commentImg {
    object-fit: scale-down;
	height: 100%;
	width: 100%;
}

.commentsText {
    color: #000000 !important;
    margin-left: 10px !important;
    text-transform: none !important;
    text-decoration: underline !important;
    margin-top: 0 !important;
    float: right;
    font-size: 14px !important;
    font-family: 'Roboto', sans-serif !important;
    padding-top: 0 !important;
}

.commentsText:hover {
    background-color:  transparent !important;
}

.complementaryInfo {
    overflow: hidden;
    width: 90%;
    margin: 15px auto 0;
}

.deleteCondolenceBtn {
	position: absolute !important;
	right: 0;
	top: 15px;
	color: #828282 !important;
}

.deleteCondolenceBtn:hover {
	background-color: transparent !important;
}

.icon {
    margin-right: 10px;
}

.likeIcon {
    width: 20px !important;
    height: 20px !important;
}

.likesNumber {
    position: relative;
    top: -5px;
    margin-left: 5px;
}

.likesText {
    font-size: 14px;
    float: left;
    color: #828282;
    font-family: 'Roboto', sans-serif;
    margin-top: 7px;
}

.noUserContainer {
    text-align: center;
}

.noUserIcon {
    width: 45px;
    height: 45px;
    margin: 16px 10px 40px;
    float: left;
    color: #828282 !important;
}
