.btnsContainer {
	text-align: center;
	font-family: 'Roboto', sans-serif;
	margin-top: 30px;
}

.carrouselContainer {
	width: 100%;
	overflow: hidden;
	position: relative;
	height: 150px;
}

@media(min-width: 1220px) {
	.carrouselContainer {
		height: 300px;
	}
}

.date {
	font-size: 10px !important;
	margin: 5px !important;
	text-transform: none !important;
	font-family: none !important;
}

@media(min-width: 1220px) {
	.date {
		font-size: 14px !important;
	}
}

.homeContainer {
    margin: 0 auto;
    max-width: 480px;
    position: relative;
    overflow: hidden;
}

.img {
	width: 100%;
	height: 100%;
}

.imgCarrousel {
	width: 280px;
	height: 147px;
}

@media(min-width: 1220px) {
	.imgCarrousel {
		width: 600px;
		height: 315px;
	}
}

.infoContainer {
	padding: 10px 20px;
}

.infoContainer__title {
	text-align: center;
	font-family: 'Roboto', sans-serif;
	font-size: 24px;
	font-weight: bold;
	margin: 30px 0;
}

.infoContainer__text {
	font-family: 'Roboto', sans-serif;
  	font-size: 18px;
	padding: 0;
	text-align: justify;
}

.infoUserContainer {
	position: absolute;
	background: #000;
	z-index: 99999;
	bottom: 5px;
	width: 100%;
	text-align: center;
	color: #FFF;
}

@media(min-width: 1220px) {
	.infoUserContainer {
		bottom: 21px;
	}
}

.inTribut__btn {
	width: 210px;
	color: #FFFFFF;
	background-color: #c94d32;
	padding: 10px 20px;
	text-decoration: none;
	font-size: 18px;
	font-family: Maitree;
	display: block;
	margin: 45px auto;
	bottom: 0;
}

.logoArquidiocesisMty {
	width: 245px;
	height: 113px;
	margin: 30px auto;
}

.partnersContainer {
	text-align: center;
	margin-top: 50px;
}

.pictureContainer {
	position: relative;
}

.seeExample__btn {
	text-align: center;
	color: #c94d32;
	font-size: 18px;
}

.showMoreBtn {
	margin: 20px auto;
	width: 152px !important;
	font-size: 50px !important;
	display: block !important;
}

@media (min-width: 600px) {
	.searchUser__input {
		width: 50%;
		margin: 0 25%;
		background-color: #fff;
	}
}

@media (min-width: 980px) {
	.searchUser__input {
		width: 30%;
		margin: 0 35%;
		background-color: #fff;
	}
}

.userName {
	font-size: 12px !important;
	margin: 5px !important;
	text-transform: none !important;
	font-family: none !important;
}

@media(min-width: 1220px) {
	.userName {
		font-size: 18px !important;
	}
}

