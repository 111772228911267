.tribut {
	padding: 30px 0;
	margin: 0 auto;
	max-width: 480px;
	font-family: 'Roboto', sans-serif;
}


.tribut__title {
	color: #604585;
	text-align: center;
	font-size: 24px;
	margin-top: 0;
	font-family: Maitree;
}


.arrowStepContainer {
	width: 70%;
	text-align: center;
	overflow: hidden;
	margin: 20px auto;
}

.tribut__step {
	text-align: center;
	font-size: 24px;
	float: left;
	width: 55%;
	margin: 5px 0;
	font-weight: bold;
	position: relative;
	top: 10px;
}


.arrowStep {
	float: left;
	color: #000000 !important;
	padding: 0 !important;
}

.arrowStep:hover {
	background-color: transparent !important;
}

.arrowStep:disabled {
	float: left;
	color: rgba(0, 0, 0, 0.26) !important;
	padding: 0 !important;
}

.arrowStepIcon {
	width: 50px !important;
	height: 60px !important;
}

@media (min-width: 500px) {
	.arrowStepContainer {
		width: 50%;
	}
}

.img {
    width: 100%;
    height: 100%;
}

.loadingImage {
    margin: 0 auto;
    width: 200px;
}