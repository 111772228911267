.allMemoriesContainer {
	position: relative;
}

.btnBack {
	color: #000 !important;
	float: left;
	position: absolute !important;
}

.btnBack:hover {
	background-color: transparent !important;
}

.btnBackIcon {
	width: 30px !important;
	height: 30px !important;
}

.memoriesContainer {
	position: relative;
}

.title {
	font-size: 24px;
	font-weight: bold;
	font-family: 'Roboto', sans-serif;
	text-align: center;
}

.TributContainer {
	margin: 0 auto;
	max-width: 480px;
	font-size: 14px;
	position: relative;
	padding-bottom: 50px;
}