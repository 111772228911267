.addMemoriesContainer {
	margin-bottom: 20px;
}

.img {
	object-fit: cover;
	object-position: center;
	border-radius: 50%;
	width: 100%;
	height: 100%;
}

.noUserContainer {
    text-align: center;
}

.noUserIcon {
    width: 30px;
	height: 30px;
	margin: 0 13px 13px;
	float: left;
}

.openModalBtn {
	color: #604585 !important;
	text-transform: none !important;
	font-size:  14px !important;
	font-family: 'Roboto', sans-serif !important;
	width: 40%;
	margin: 0 5% !important;
	padding-top: 3px !important;
	padding-bottom: 0 !important;
}

.openModalBtn:hover {
	background-color: transparent !important;
}

.iconMedia {
	margin: 0 10px 0 0;
	width: 23px;
	height: 23px;
}

.imgIcon {
	width: 100%;
	height: 100%;
}

.openModalIcon {
	margin-right: 5px;
}

.rightSection {
	border-radius: 16px;
  	background-color: #eaeaea;
	overflow-y: scroll;
	min-height: 30px;
}

.userImage {
	width: 30px;
	height: 30px;
	margin: 0 13px 13px;
	float: left;
}