.btnShowFields {
    font-size: 16px !important;
    color: #424147 !important;
    text-transform: lowercase !important;
    padding: 0 !important;
    width: 100px;
    top: -1px;
    text-decoration: underline !important;
    font-weight: bold !important;
}

.btnShowFields:hover {
    background-color: transparent !important;
}

.createAccountBtn {
    color: #FFFFFF !important;
    border: #604585 1px solid !important;
    background-color: #604585 !important;
    cursor: pointer !important;
    box-shadow: 6px 5px 10px -3px rgba(0,0,0,0.75) !important;
    -webkit-box-shadow: 6px 5px 10px -3px rgba(0,0,0,0.75) !important;
    -moz-box-shadow: 6px 5px 10px -3px rgba(0,0,0,0.75) !important;
    border-radius: 5px !important;
    padding: 10px !important;
    font-family: Maitree !important;
    height: 50px !important;
    font-size: 18px !important;
    margin-top: 20px !important;
    text-transform: none !important;
}

.createAccountBtn {
    background-color: #604585 !important;
}

.createAccountContainerBtn {
    text-align: center;
    border-top: 1px solid black;
}

.editorContainer {
    width: 80%;
    margin: 20px auto 0;
}

.formContainer {
    position: relative;
}

.generalData {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
    width: 80%;
    margin: 20px auto 0;
}

.img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.infoText {
    width: 70%;
    margin: 40px auto 10px;
}

.loadImage {
    width: 80%;
    margin: 0 auto;
}

.loginBtn {
    color: #FFFFFF !important;
    border: #c94d32 1px solid !important;
    background-color: #c94d32 !important;
    cursor: pointer !important;
    box-shadow: 6px 5px 10px -3px rgba(0,0,0,0.75) !important;
    -webkit-box-shadow: 6px 5px 10px -3px rgba(0,0,0,0.75) !important;
    -moz-box-shadow: 6px 5px 10px -3px rgba(0,0,0,0.75) !important;
    border-radius: 5px !important;
    padding: 10px !important;
    font-family: Maitree !important;
    height: 50px !important;
    font-size: 18px !important;
    margin-top: 20px !important;
    text-transform: none !important;
    width: 200px;
}

.loginBtn:hover {
    background-color: #c94d32 !important;
}

.loginBtn:disabled {
    background-color: #c6c6c6 !important;
    border: #c6c6c6 1px solid !important;
}

.loginContainer {
    margin-bottom: 20px;
    text-align: center;
}

.message {
    width: 80%;
    font-size: 16px;
    margin: 30px auto;
    font-family: 'Roboto', sans-serif;
    text-align: center;
}

.noUserContainer {
    text-align: center;
}

.noUserIcon {
    width: 180px !important;
    height: 180px !important;
    color: #828282 !important;
}

.specialContainer {
    position: relative;
}

.title {
	color: #604585;
	text-align: center;
	font-size: 24px;
	font-family: Maitree;
}

.totalCharacters {
    font-size: 12px;
    text-align: right;
    width: 80%;
    margin: 0 auto;
    position: relative;
    top: -15px;
}

.tributAvatar {
    width: 180px;
    height: 180px;
    margin: 25px auto;
}

.tributStepSubitle {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    width: 80%;
    margin: 0 auto 20px;
    text-align: center;
}

.tributStepTitle {
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    width: 80%;
    margin: 0 auto;
}

.uploadImageText {
    font-family: 'Roboto', sans-serif;
    text-align: center;
    margin-bottom: 0;
}

.uploadImageTitle {
    margin-top: 25px;
    margin-bottom: 20px;
}

.uploadImageSubText {
    font-family: 'Roboto', sans-serif;
    text-align: center;
    color: #828282;
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 25px;
}