.acceptEventBtn {
    font-size: 18px !important;
    color: #c94d32 !important;
}

.acceptEventBtn:hover {
    background: transparent !important;
}

.btnsContainer {
    text-align: center;
    border-bottom: 2px solid #000;
    padding-bottom: 25px;
    margin-bottom: 20px;
}

.cancelEventBtn {
    font-size: 18px !important;
    color: #828282 !important;
}

.cancelEventBtn:hover {
    background: transparent !important;
}

.date {
    margin-top: 10px !important;
    width: 50%;
    border-top-right-radius: 0 !important;
}

.date fieldset {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
}

.dateText {
    position: absolute !important;
    width: 50%;
    right: 0;
    margin-top: 10px !important;
}

.dateText fieldset {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
}

.dateText input::placeholder {
    font-size: 13px;
    font-style: italic;
}

.eventContainer {
    width: 80%;
    margin: 20px auto;
    position: relative;
    overflow: hidden;
    font-family: 'Roboto', sans-serif;
}

.eventContainerEdit {
    width: 100%;
    margin: 20px auto;
    position: relative;
    overflow: hidden;
    font-family: 'Roboto', sans-serif;
}

.inputField {
    width: 100%;
}

.totalCharacters {
    font-size: 12px;
    text-align: right;
    margin-bottom: 10px;
}

.tributStepTitle {
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
}

.specialContainer {
    position: relative;
}