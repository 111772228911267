.recoverAccountContainer {
    margin: 0 auto;
    max-width: 480px;
    position: relative;
}

.recoverAccountTitle {
    text-align: center;
    font-size: 24px;
    color: #604585;
    font-weight: bold;
    font-family: Maitree;
}
