.TributContainer {
	margin: 0 auto;
	max-width: 480px;
	font-size: 14px;
	position: relative;
	padding-bottom: 50px;
}

.Tribut form {
	margin: 0 auto;
	max-width: 480px;
}

.form-group {
	margin-bottom: 15px;
}

.form-control {
	display: block;
	width: 100%;
	height: 34px;
	padding: 6px 12px;
	font-size: 16px;
	line-height: 1.42857143;
	border: 1px solid #ccc;
}

.btn {
	margin-bottom: 0;
	font-weight: 400;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	touch-action: manipulation;
	user-select: none;
	background-image: none;
	border: 1px solid transparent;
}

.btn-block {
	display: block;
	width: 100%;
}

.btn-default {
	color: #333;
	background-color: #fff;
	border-color: #ccc;
}

.btn-lg {
	padding: 10px 16px;
	font-size: 18px;
	line-height: 1.3333333;
	border-radius: 6px;
}

.control-label {
	max-width: 100%;
	margin-bottom: 5px;
	font-weight: 700;
	font-size: 16px;
}

.icon {
	display: block;
	text-align: center;
	margin: 0px;
	width: 130px;
}

.tab-list {
	border-bottom: 1px solid #ccc;
	padding-left: 0;
}

.tab-list-item {
	display: inline-block;
	list-style: none;
	margin-bottom: -1px;
	padding: 0.5rem 0.75rem;
}

.tab-list-active {
	background-color: white;
	border: solid #ccc;
	border-width: 1px 1px 0 1px;
}

.my-carousel {
	width: calc(100% - 2px);
	height: 300px;
	border: 1px solid #ccc;
}

.img {
    width: 100%;
    height: 100%;
}

.loadingImage {
    margin: 0 auto;
    width: 200px;
}