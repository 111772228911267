.addMemoriesBtn {
	border-radius: 8px !important;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5) !important;
	background-color: #c94d32 !important;
	color: #FFF !important;
	font-size: 18px !important;
	margin: 0 auto !important;
	width: 300px !important;
	text-transform: none !important;
}

.addMemoriesBtn:hover {
	background-color: #c94d32 !important;
	color: #FFF;
}

.addMemoryText {
	font-size: 20px;
	font-weight: bold;
	text-align: center;
	font-family: 'Roboto', sans-serif;
	width: 300px;
	margin: 35px auto;
}

.btn {
	margin-bottom: 0;
	font-weight: 400;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	touch-action: manipulation;
	user-select: none;
	background-image: none;
	border: 1px solid transparent;
}

.btnContainer {
	text-align: center;
	margin-bottom: 45px;
}

.btnFloat {
	position: 'fixed';
	bottom: 10%;
	right: 10%;
}

.btn-block {
	display: block;
	width: 100%;
}

.btn-default {
	color: #333;
	background-color: #fff;
	border-color: #ccc;
}

.btn-lg {
	padding: 10px 16px;
	font-size: 18px;
	line-height: 1.3333333;
	border-radius: 6px;
}

.control-label {
	max-width: 100%;
	margin-bottom: 5px;
	font-weight: 700;
	font-size: 16px;
}

.form-group {
	margin-bottom: 15px;
}

.form-control {
	display: block;
	width: 100%;
	height: 34px;
	padding: 6px 12px;
	font-size: 16px;
	line-height: 1.42857143;
	border: 1px solid #ccc;
}

.icon {
	display: block;
	text-align: center;
	margin: 0px;
	width: 130px;
}

.imagesContainer {
	width: 90%;
	height: 450px;
}

.imageContainer {
	background-color: #C4C4C4;
}

.invitationText {
	font-size: 18px;
	font-family: Maitree !important;
	text-align: center;
	width: 50%;
	margin: 5px auto 20px;
	line-height: 1.39;
}

.itemCollage {
	cursor: pointer;
	object-fit: contain !important;
	height: 100% !important;
	width: 100% !important;
}

.memory {
	margin: 0 auto;
	max-width: 480px;
	font-size: 14px;
}

.memory form {
	margin: 0 auto;
	max-width: 480px;
}

.number {
	font-weight: bold;
	color: #604585;
	text-decoration: underline;
}

.totalMemories {
	font-size: 18px;
	font-family: 'Roboto', sans-serif;
	margin-left: 20px;
	margin-bottom: 45px;
}

.uploadIcon {
	width: 30px;
	height: 30px
}
