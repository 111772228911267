.btnOptions {
    text-transform: none !important;
    color: #000 !important;
    font-size: 14px !important;
    padding: 0 !important;
}

.imgAvatar {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.loginMenuContainer {
    border-bottom: 1px solid #604585;
    padding: 10px 0;
    text-align: center;
}
.loginBtn {
    background-color: #c94d32;
    border-radius: 10px;
    width: 80%;
    color: #fff;
    text-transform: none;
    font-size: 20px;
    display: block;
    margin: 0 auto;
    text-decoration: none;
    padding: 5px;
    text-align: center;
    font-family: Maitree;
}

.loginText {
    font-size: 14px;
    margin-bottom: 0;
    font-family: 'Roboto', sans-serif;
    line-height: normal;
    letter-spacing: normal;
}

.noUserIcon {
    margin: 0;
    width: 43px !important;
    height: 43px !important;
    color: #828282 !important;
    float: left;
}

.optionsContainer {
    width: 100%;
    float: left;
    margin-top: 15px;
}

.userAvatar {
    margin: 0;
    width: 43px;
    height: 43px;
    float: left;
}

.userName {
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    margin: 10px 10px 10px 5px;
    float: left;
}

.userOptions {
    padding: 16px;
    overflow: hidden;
    width: 250px;
}