.admintitle {
    font-size: 18px;
    margin: 15px 10px;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
}

.altarIcon {
    width: 36px;
    height: 36px;
    margin: 0 10px 10px 0;
    float: left;
}

.btnOptions {
    text-transform: none !important;
    color: #000 !important;
    padding: 0 !important;
    display: block !important;
    width: 90%;
    margin: 10px !important;
    text-decoration: none !important;
    font-size: 18px !important;
    font-family: 'Roboto', sans-serif !important;
}

.btnOptions:hover {
    background-color: transparent !important;
}

.contactUs__link {
    display: block;
    width: 90%;
    margin: 10px 15px;
    color: black;
    text-decoration: none;
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
}

.container {
    background-color: #e6e5e5;
    border-bottom: 2px solid #604585;
}

.createTributBtn {
    background-color: #c94d32 !important;
    color: #FFF !important;
    text-transform: none !important;
    font-size: 18px !important;
    margin: 20px 15px 15px !important;
    text-decoration: none;
    font-family: Maitree !important;
}

.extraInformation__link {
    display: block;
    margin: 10px 0;
    color: black;
    text-decoration: none;
    font-size: 18px;
}

.img {
    width: 100%;
    height: 100%;
}

.imgFooter {
    width: 50px;
    height: 50px;
}

.loginBtn__icon {
    color: #000;
    float: left;
    font-size: 25px;
    margin: 0 25px 0 20px;
}

.moreInformationContainer {
    padding: 10px;
}

.moreInformation__link {
    display: block;
    width: 90%;
    margin: 10px;
    color: black;
    text-decoration: none;
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
}

.menuContainer {
    background-color: #fff;
    padding: 0 !important;
    width: 100%;
}

.sectionContainer {
    border-top: 1px solid #604585;
    padding: 0 10px;
    overflow: hidden;
}

.specialText {
    position: relative;
    top: 5px;
    font-weight: bold;
}

.tributLogo {
    margin: 34px 22px;
    width: 202px;
}

.tributFooterLogo {
    background-color: #b78a33;
    border-bottom: 2px solid #000;
    bottom: 0;
    margin: 0;
    width: 250px;
    padding: 10px;
    text-align: center;
}