.carrouselContainer {
    width: 100%;
    height: 300px;
}

.imageContainer {
    width: 100%;
    height: 300px;
    object-fit: cover !important;
	object-position: center !important;
}

.videoContainer {
    width: 100%;
    height: 300px;
    object-fit: cover !important;
	object-position: center !important;
}