.specialContainer {
    position: relative;
}

.principalText {
    font-family: 'Roboto', sans-serif;
    margin: 25px 0 17px;
    font-size: 16px;
}

.uploadImageText {
    font-family: 'Roboto', sans-serif;
    text-align: center;
    margin: 0;
    font-size: 16px;
}

.uploadImageSubText {
    font-family: 'Roboto', sans-serif;
    text-align: center;
    color: #828282;
    font-size: 12px;
    margin-top: 0;
    margin-bottom: 25px;
}