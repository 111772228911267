.container {
    margin: 0 auto;
    max-width: 480px;
}

.text {
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    width: 80%;
    margin: 15px auto;
}

.title {
    text-align: center;
    font-family: 'Roboto', sans-serif;
    margin-top: 30px;
    font-size: 24px;
    font-weight: bold;
}