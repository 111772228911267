.cancelBtn {
    font-family: Maitree !important;
    background-color: #828282 !important;
    color: #FFFFFF !important;
    width: 110px;
    height: 40px;
    margin: 30px 20px !important;
}

.containerBtns {
    text-align: center;
}

.helpBtn {
    text-transform: none !important;
    color: #890f20 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    font-size: 16px !important;
}

.recoverAccountText {
    width: 80%;
    margin: 10px 10% 20px;
    text-align: center;
    font-family: 'Roboto', sans-serif;
}

.selectOptionContainer {
    width: 80%;
    margin: 0 10% !important;
}

.sendBtn {
    font-family: Maitree !important;
    background-color: #c94d32 !important;
    color: #FFFFFF !important;
    width: 110px;
    height: 40px;
    margin: 30px 20px !important;
}