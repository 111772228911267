.icon {
    width: 28px !important;
    height: 28px !important;
    color: #604585;
}

.iconBtn {
    padding: 0 5px !important;
}

.iconBtn:hover {
    background-color: transparent !important;
}

.iconContainer {
    width: 150px;
    text-align: center;
}

.iconsContainer {
    display: flex;
    justify-content: center;
    width: 100px;
    float: right;
    margin-right: 18px;
    position: absolute;
    top: 2px;
    right: 0;
}

.iconsContainerBottom {
    display: flex;
    justify-content: center;
    width: 100px;
    float: right;
    margin-right: 18px;
    position: relative;
    bottom: 15px;
    right: 0;
}

.iconText {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    font-size: 14px;
}