.containerBtns {
    text-align: center;
}

.recoverAccountText {
    width: 80%;
    margin: 10px 10% 20px;
    text-align: center;
    font-family: 'Roboto', sans-serif;
}

.selectOptionContainer {
    width: 70%;
    margin: -30px 15% 0 !important;
}

.sendBtn {
    font-family: Maitree !important;
    background-color: #c94d32 !important;
    color: #FFFFFF !important;
    width: 140px;
    height: 40px;
    margin: 30px 20px !important;
    font-family: Maitree !important;
    text-transform: none !important;
    font-size: 18px !important;
}