.addImageIcon {
    position: absolute;
    top: 20px;
    width: 40px !important;
    height: 40px !important;
    color: #FFF;
    left: 70px;
}

.addImageText {
    position: absolute;
    bottom: 20px;
    left: 50px;
    color: #FFF;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
}

.dropZoneCondolenceContainer {
    border: none !important;
}

.dropZoneContainer {
    margin: 10px auto;
    cursor: pointer;
    border: 2px solid #eeeeee;
    border-radius: 2px;
    text-align: center;
}

.dropZoneAvatarContainer {
    width: 180px;
    height: 180px;
    margin: 10px auto;
    overflow: hidden;
    cursor: pointer;
    border: 2px solid #eeeeee;
    text-align: center;
    border-radius: 50%;
}

.dropZoneMemoryContainer {
    width: 100%;
    border-radius: 5px !important;
    margin: 20px auto 30px;
    overflow: hidden;
    cursor: pointer;
    border: 2px solid #979797;
    border-radius: 2px;
    text-align: center;
    overflow-y: scroll;
    background-color: #fbfbfb;
}

.avatarDropZone {
    width: 100%;
    height: 100%;
    margin: 0;
}

.icon {
    width: 28px !important;
    height: 28px !important;
    color: #604585;
}

.iconBtn {
    padding: 0 5px !important;
}

.iconBtn:hover {
    background-color: transparent !important;
}

.img {
    width: 100%;
    height: 100%;
    filter: grayscale(30%);
}

.imgAvatar {
    background-color: rgba(11, 19, 20, 0.5);
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    width: 100%;
    height: 100%;
}

.imageAvatarContainer {
    width: 180px;
    height: 180px;
    background-color: #bab9b9;
}

.imageContainer {
    width: 150px;
    height: 115px;
    position: relative;
    background-color: rgba(11, 19, 20, 0.5);
    margin: 0 auto;
}

.memoriesIcon {
    width: 50px !important;
    height: 50px !important;
    background-color: #d8d8d8;
    padding: 10px;
    border-radius: 50%;
}

.memoriesUploadContainer {
    padding: 60px 0 80px;
}

.memoriesUploadSubtext {
    font-size: 14px;
}

.memoriesUploadText {
    margin: 0;
    padding: 10px;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    width: 100%;
}

.myImageContainer {
    position: relative;
    background-color: rgba(11, 19, 20, 0.5);
    margin: 0 auto;
    object-fit: scale-down;
}

.noUserContainer {
    text-align: center;
}

.noUserIcon {
    width: 142px !important;
    height: 142px !important;
    color: #828282 !important;
}

.videoContainer {
    height: 250px;
}
