.header {
  background: #e6e5e5;
  box-shadow: none;
  height: 50px;
}
.header .menuIcon {
  font-size: 35px;
  color: #604585;
}
.header .inTributIconContainer {
  width: calc(100% - 100px);
}
.header .inTributIconContainer .inTributICon {
  margin: 0 auto;
  height: 42px;
  width: 202px;
}
.header .inTributIconContainer .inTributICon .imgHeader {
  width: 100%;
  height: 100%;
}
.header .btnCampus {
  display: none;
  text-transform: capitalize;
  font-size: 16px;
  right: 100px;
  position: absolute;
  border: 1px solid gray;
  border-radius: 0;
  padding: 0 20px;
  letter-spacing: 2px;
}

.img {
  height: 100%;
  width: 100%;
}

@media (min-width: 1280px) {
  .header .btnCampus {
      display: block;
  }
}