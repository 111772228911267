.faqsContainer {
    margin: 30px 20px;
    font-family: 'Roboto', sans-serif;
}

@media(min-width: 1220px) {
	.faqsContainer {
        margin: 30px 50px;
    }
}

.faqsTitle {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000;
    text-align: center;
    margin-bottom: 35px;
}

.faqsQuestion {
    font-size: 21px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000;
    margin-bottom: 20px;
}

@media(min-width: 1220px) {
	.faqsQuestion {
        font-size: 24px;
    }
}

.faqsAnswer {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000;
    margin-bottom: 60px;
    text-align: justify;
}