.acceptBtn {
    background-color: #c94d32 !important;
    color: #FFF !important;
    width: 45% !important;
    height: 42px !important;
    font-family: Maitree !important;
    float: right;
}

.acceptBtn:hover {
    background-color: #c94d32 !important;
}

@media (min-width: 470px) {
    .acceptBtn {
        width: 135px !important;
    }
}

.btnsActionContainer {
    text-align: center;
}

.cancelBtn {
    background-color: #828282 !important;
    color: #FFF !important;
    width: 45% !important;
    height: 42px !important;
    font-family: Maitree !important;
    float: left;
}

.cancelBtn:hover {
    background-color: #828282 !important;
}

@media (min-width: 470px) {
    .cancelBtn {
        width: 135px !important;
        margin-right: 25px !important;
    }
}

.changeViewBtn {
    color: #c94d32 !important;
    text-decoration: underline !important;
}

.changeViewBtn:hover {
    background-color: transparent !important;
}

.changeViewBtnContainer {
    text-align: center;
}

.closeModalBtn {
    position: absolute !important;
    right: 0;
    top: 0;
    color: #000 !important;
    font-size: 30px !important;
}

.closeModalBtn:hover {
    background: transparent !important;
}

.closeModalIcon {
    font-size: 30px !important;
}

.createAccountBtn {
    color: #FFFFFF !important;
    border: #604585 1px solid !important;
    background-color: #604585 !important;
    cursor: pointer !important;
    box-shadow: 6px 5px 10px -3px rgba(0,0,0,0.75) !important;
    -webkit-box-shadow: 6px 5px 10px -3px rgba(0,0,0,0.75) !important;
    -moz-box-shadow: 6px 5px 10px -3px rgba(0,0,0,0.75) !important;
    border-radius: 5px !important;
    padding: 10px !important;
    font-family: Maitree !important;
    height: 50px !important;
    font-size: 18px !important;
    margin-top: 40px !important;
    text-transform: none !important;
}

.registerContainer {
    border-top: 1px solid #979797;
    text-align: center;
}

.registerText {
    text-align: center;
    font-family: 'Roboto', sans-serif;
}

.subtitle {
    text-align: center !important;
    font-size: 24px !important;
    color: #604585 !important;
    font-weight: bold !important;
    font-family: Maitree !important;
}

.subTitle {
    font-size: 20px !important;
    font-family: 'Roboto', sans-serif !important;
    margin: 27px auto !important;
    text-align: center;
    color: #000 !important;
}

.title {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #d8d8d8;
    font-size: 20px;
    font-weight: bold;
}

.userFormContainer {
    width: 100vw;
    background-color: #FFF;
    position: fixed;
    top: 0;
    height: 100vh;
    z-index: 1;
    left: 0;
    overflow-y: scroll;
    padding-bottom: 200px;
}