.btnAction {
    margin: 10px;
}

.btnRecordAction {
    background: #e60909;
    border-radius: 50%;
    border: 4px solid white;
    margin: 5px;
    width: 50px;
    height: 50px;
    cursor: pointer;
}

.btnStopAction {
    background-color: transparent;
    border-radius: 50%;
    border: 4px solid white;
    margin: 5px;
    width: 50px;
    height: 50px;
    position: relative;
    cursor: pointer;
}

.btnsContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
}

.loadingContainer {
	position: fixed;
	top: 0;
	width: 100vw;
	background: #fff;
	height: 100vh;
	z-index: 9999;
	left: 0;
}

.noShow {
	display: none;
}

.recordContainer {
    position: relative;
}

.stopIcon {
    width: 45px !important;
    height: 45px !important;
    color: #e60909;
    position: absolute;
    left: -1px;
    top: -1px;
}

.timeTextRecording {
    position: absolute;
    width: 30%;
    text-align: center;
    margin: 0 35%;
    background: red;
    font-size: 24px;
    color: white;
    top: 5px;
}

.timeText {
    position: absolute;
    width: 100%;
    text-align: center;
    background: rgba(0, 0, 0, 0.5);
    font-size: 24px;
    color: white;
    top: 0;
    margin: 0;
}

.videoContainer {
    width: 100%;
    position: relative;
}