.contactContainer {
    margin: 30px auto;
    max-width: 480px;
    font-family: 'Roboto', sans-serif;
}

.contactOption {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000;
    margin-bottom: 60px;
    font-weight: bold;
    width: 80%;
    margin: 20px auto;
}

.contactTitle {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000;
    text-align: center;
    margin-bottom: 35px;
}

.contactText {
    width: 90%;
    margin: 10px auto;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000;
    margin-bottom: 60px;
    text-align: center;
}