.btnsContainer {
    text-align: center;
    margin-bottom: 40px;
}

.recoverBtn {
    background-color: #c94d32 !important;
    color: #FFF !important;
    text-transform: none !important;
    width: 130px !important;
    margin-left: 20px !important;
    font-size: 14px !important;
}

.recoverBtn:hover {
    background-color: #c94d32 !important;
    color: #FFF !important;
}

.textInfo {
    color: #000 !important;
    font-size: 20px !important;
    margin-bottom: 15px !important;
    text-align: center;
}