.generalData {
	background-color: red;
	width: 100%;
}

.loadImage {
    width: 80%;
    margin: 0 auto;
}

.register {
	margin: 0 auto;
	max-width: 480px;
	font-size: 14px;
}

.register form {
	margin: 0 auto;
	max-width: 320px;
}

.title {
	color: #604585;
	text-align: center;
	font-size: 24px;
	font-family: Maitree;
}

.uploadImageText {
    font-family: 'Roboto', sans-serif;
    text-align: center;
}