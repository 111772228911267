.changepasswordText {
	font-family: 'Roboto', sans-serif;
	width: 80%;
	margin: 10px auto;
}

.changePasswordVewBtn {
	font-family: Maitree !important;
	font-size: 16px !important;
	font-weight: normal !important;
	font-stretch: normal !important;
	font-style: normal !important;
	line-height: normal !important;
	letter-spacing: normal !important;
	color: #c94d32 !important;
	text-decoration: underline !important;
	margin-left: 32px !important;
}

.changePasswordVewBtn:hover {
	background-color: transparent !important;
}

.loadContainer {
	position: relative;
	top: 50px;
}

.loadImage {
    width: 80%;
    margin: 0 auto;
}

.passwordTitle {
	font-family: 'Roboto', sans-serif;
	font-size: 18px;
	font-weight: bold;
	width: 80%;
	margin: 20px auto;
}

.phoneContainer {
	width: 80%;
	margin: 20px auto;
}

.phoneText {
	font-family: 'Roboto', sans-serif;
	font-size: 18px;
	margin-bottom: 0;
}

.register {
	margin: 0 auto;
	max-width: 480px;
	font-size: 14px;
}

.register form {
	margin: 0 auto;
	max-width: 405px;
}

.uploadImageText {
    font-family: 'Roboto', sans-serif;
    text-align: center;
	font-size: 16px;
	margin-bottom: 0;
}

.uploadImageSubText {
    font-family: 'Roboto', sans-serif;
    text-align: center;
    color: #828282;
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 25px;
}
