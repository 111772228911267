.changeViewBtn {
	width: 200px !important;
	color: #c94d32 !important;
	text-decoration: underline !important;
	margin: 0 auto !important;
    display: block !important;
}

.changeViewBtn:hover {
	background-color: transparent !important;
}


.closeModalBtn {
	position: absolute !important;
	right: 0;
	top: 5px;
	color: #000 !important;
	font-size: 30px !important;
}

.closeModalBtn:hover {
	background: transparent !important;
}

.closeModalIcon {
	font-size: 40px !important;
}

.emailContainer {
	font-size: 18px;
	width: 100%;
	border-bottom: 1px solid #d8d8d8;
}

.emailInfo {
	width: 85%;
  	margin: 0 auto;
}

@media(min-width: 600px) {
	.emailInfo {
        width: 60%;
    }
}

.emailMemory {
	text-align: center;
	color: #0035e3;
	text-decoration: underline;
	display: block;
	margin-bottom: 20px;
}

.img {
	width: 100%;
	height: 100%;
	border-radius: 50%;
}

.messageText {
	font-size: 16px;
	text-align: center;
	font-family: 'Roboto', sans-serif;
}

.noUserContainer {
    text-align: center;
	margin: 0 13px 13px;
	float: left;
}

.noUserIcon {
    width: 30px !important;
    height: 30px !important;
    color: #828282 !important;
}

.subtitle {
	text-align: center;
	font-family: 'Roboto', sans-serif;
}

.title {
	text-align: center;
	font-size: 20px;
	font-family: 'Roboto', sans-serif;
	font-weight: 900;
	border-bottom: 1px solid #d8d8d8;
}

.userImage {
	margin: 0;
	height: 43px;
	width: 43px;
	float: left;
}

.userInfoConatiner {
	margin-top: 20px;
	overflow: hidden;
}

.userName {
	font-family: 'Roboto', sans-serif;
	font-weight: bold;
	margin-left: 10px;
	margin-top: 10px;
	float: left;
}