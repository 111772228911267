.avatar {
    width: 75px;
    height: 75px;
    margin: 0 auto;
    position: relative;
    top: 10px;
}

.avatar_image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.btnBack {
    text-transform: none !important;
    font-size: 16px !important;
    color: #000000 !important;
    padding-left: 0 !important;
}

.btnEditUserIcon {
    width: 15px !important;
    height: 15px !important;
}

.btnEditUserInfo {
    position: absolute !important;
    font-size: 11px !important;
    color: #FFFFFF !important;
    text-transform: none !important;
    right: 0;
}

.btnEditUserText {
    position: absolute;
    top: 20px;
}

.tributSelectedContainer {
    width: 90%;
    margin: 0 auto;
}

.name {
    font-family: 'Roboto', sans-serif;
    text-align: center;
    margin: 0 0 10px 0;
    padding-top: 20px;
}

.noUserContainer {
    text-align: center;
}

.noUserIcon {
    width: 75px !important;
    height: 75px !important;
    color: #828282 !important;
}

.userInfo {
    overflow: hidden;
    background-color: #c94d32;
    color: #FFFFFF;
    position: relative;
}



















.generalInfo {
    background-color: #E7D8BC;
    margin: 50px 0 30px;
    float: right;
    width: 90%;
}

.itemInfo {
    margin-left: 20px;
}

.itemText {
    padding-left: 1%;
}



.principalContainer {
    background-color: #C19B50;
    width: 90%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    margin-top: 30px;
}

.specificInfo {
    background-color: #FFDE9F;
    margin: 50px 0 30px;
    float: left;
    width: 60%;
}

