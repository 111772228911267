.btnSubmitComment {
	height: 50px;
	margin-top: 0px;
	position: relative;
	top: 5px;
	border-radius: 0;
	background: transparent;
	border: none;
	float: right;
	cursor: pointer;
	color: #000 !important;
}

.btnSubmitComment:hover {
	background-color: transparent !important;
	color: #000 !important;
}

.changeViewBtnContainer {
	text-align: center;
}

.changeViewBtn {
	font-family: Maitree !important;
    width: 200px !important;
    color: #c94d32 !important;
    text-decoration: underline !important;
}

.closeModalBtn {
    position: absolute !important;
    right: 0;
    top: 0;
    color: #000 !important;
    font-size: 30px !important;
}

.closeModalBtn:hover {
    background: transparent !important;
}

.commentAvatar {
    width: 31px;
    height: 31px;
    margin: 17px 10px 10px 10px;
    float: left;
}

.commentAvatarImg {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.commentCondolenceField {
	width: 100%;
	position: relative !important;
	top: -13px;
	border: none !important;
	background: #eaeaea !important;
	border-radius: 20px !important;
}

.commentCondolenceField:focus {
    outline: none !important;
	border: none !important;
}

.commentCondolenceField fieldset {
    border: 0 !important;
	padding-bottom: 0 !important;
}

.formCommentCondolence {
	width: 100%;
	overflow: hidden;
}

.inputContainer {
	overflow: hidden;
	width: 65%;
	position: relative;
	top: 15px;
	border-radius: 20px !important;
	height: 30px;
	float: left;
}

.noUserContainer {
    text-align: center;
}

.noUserIcon {
    color: #828282 !important;
	width: 31px;
    height: 31px;
    margin: 17px 10px 10px 10px;
    float: left;
}

.subtitle {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    text-align: center;
}
