.ageText {
	margin-top: 0;
}

.bioGeneralDataContainer {
	font-family: 'Roboto', sans-serif;
	margin: 25px auto;
	width: 90%;
	border-top: 1px solid #604585;
}

.bioGeneralData__phrase {
    letter-spacing: normal;
	font-family: Maitree;
	color: #604585;
	width: 100%;
	margin: 25px auto;
	font-size: 24px;
	line-height: 30px;
	text-align: center;
}

.infoText {
	width: 90%;
	display: inline-grid;
	font-weight: bold;
}

.ocurrenceContainer {
	width: 85%;
	margin: 0 auto;
	overflow: hidden;
}

.ocurrenceSpecificDataContainer {
	margin: 0;
	width: 80%;
	border-left: 2px solid #c94d32;
	float: left;
}

.ocurrenceText {
	font-family: 'Roboto', sans-serif;
	padding-left: 20px;
	margin: 2px 0;
	font-size: 16px;
}

.ocurrenceTitle {
	font-family: 'Roboto', sans-serif;
	font-size: 18px;
	margin: 0;
	font-weight: bold;
}

.ocurrenceTitle::before {
	content: "\2022";
	color: #c94d32;
	font-size: 50px;
	position: relative;
	top: 10px;
	margin-right: 10px;
	margin-left: -10px;
}

.ocurrenceYearsContainer {
	width: 15%;
	float: left;
	position: relative;
	top: 12px;
	font-size: 16px;
	font-family: 'Roboto', sans-serif;
	margin-right: 5%;
	text-align: center;
}

.yearText {
	font-weight: bold;
	margin-bottom: 5px;
}

/* .bioContainer {
	margin: 10px auto;
	max-width: 480px;
	font-size: 16px;
}

.bioSpecificDataContainer {
	margin: 0 0 20px;
	width: 80%;
	border-left: 2px solid #c94d32;
}

.bioSpecificData__text {
	font-family: 'Roboto', sans-serif;
	padding-left: 35px;
	margin: 0;
}

.bioGeneralData__text {
	margin: 10px auto;
	width: 90%;
}

.bioGeneralData__title {
	text-align: center;
	font-weight: bold;
	font-size: 18px;
	margin-top: 25px;
}


.bioGeneralData__secondTitle {
	text-align: center;
	font-weight: bold;
	font-size: 18px;
	margin-top: 25px;
	font-family: Maitree;
}

.btn-memoriam {
	width: 170px;
	border-color: orange;
	border-width: 1px;
	border-style: solid;
}

.container-vert {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.container-vert-h3{
	padding-bottom: 2%;
}

.container-vert-p {
	display: inline-block;
	width: 50%;
	padding: 2%;
}

.img {
    width: 100%;
    height: 100%;
}

.loadingImage {
    margin: 0 auto;
    width: 200px;
}

.quotationMarks {
	color: #604585;
	font-size: 50px;
}

.sectionTitle {
	font-size: 20px;
	text-align: center;
}

.tirbuteVideo {
	width: 100%;
}

 */
