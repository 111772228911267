.barProgress {
    margin: 0;
    width: 100%;
    height: 10px;
}

.closeModalBtn {
	position: absolute !important;
	right: 0;
	top: 0;
	color: #000 !important;
	font-size: 40px !important;
}

.img {
    width: 100%;
    height: 100%;
}

.messageText {
    font-size: 20px !important;
    text-align: center;
    margin-top: 20px !important;
}

.messageTextDelete {
    font-size: 20px !important;
    text-align: center;
    margin-top: 0px !important;
}

.messageTextVideo {
    font-size: 18px !important;
    text-align: justify;
    margin-top: 0px !important;
    color: #000 !important;
}

.mortuatyInfo {
    text-align: left !important;
    margin-top: 35px !important;
}

.progressContainer {
    margin: 10px auto 30px;
    width: 80%;
}

.title {
    font-weight: bold !important;
    font-size: 24px !important;
    text-align: center;
    width: 90%;
    margin: 50px auto 0 !important;
}

.titleDelete {
    font-weight: bold !important;
    font-size: 24px !important;
    text-align: center;
    width: 90%;
    margin: 20px auto 0 !important;
}
