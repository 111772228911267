.allMemoriesContainer {
	position: relative;
}

.btnBack {
	color: #000 !important;
	float: left;
	position: absolute !important;
	top: -5px;
}

.btnBack:hover {
	background-color: transparent !important;
}

.btnBackIcon {
	width: 30px !important;
	height: 30px !important;
}

.loadingContainer {
	position: fixed;
	top: 0;
	width: 100vw;
	background: #fff;
	height: 100vh;
	z-index: 9999;
	left: 0;
}

.memoriesContainer {
	position: relative;
}

.memoryContainer {
	position: relative;
	margin-bottom: 100px;
}

.noShow {
	display: none;
}

.title {
	font-size: 24px;
	font-weight: bold;
	font-family: 'Roboto', sans-serif;
	text-align: center;
	margin: 10px 0;
}
