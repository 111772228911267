.btnsContainer {
    text-align: center;
    margin-bottom: 40px;
}

.cancelBtn {
    font-family: Maitree !important;
    background-color: #828282 !important;
    color: #FFF !important;
    text-transform: none !important;
    width: 115px !important;
    margin-right: 0px !important;
    font-size: 18px !important;
}

.cancelBtn:hover {
    background-color: #828282 !important;
    color: #FFF !important;
}

.deleteBtn {
    font-family: Maitree !important;
    background-color: #c94d32 !important;
    color: #FFF !important;
    text-transform: none !important;
    width: 165px !important;
    margin-left: 20px !important;
    font-size: 18px !important;
}

.deleteBtn:hover {
    background-color: #c94d32 !important;
    color: #FFF !important;
}

.textInfo {
    color: #000 !important;
    font-size: 20px !important;
    margin: 15px auto !important;
    text-align: center;
    width: 80%;
}