.btnsContainer {
    text-align: center;
    margin-bottom: 30px;
    margin-top: 35px;
}

.cancelBtn {
    font-family: Maitree !important;
    background-color: #828282 !important;
    color: #FFF !important;
    text-transform: none !important;
    width: 100px !important;
    margin-right: 5px !important;
    font-size: 14px !important;
}

.cancelBtn:hover {
    background-color: #828282 !important;
    color: #FFF !important;
}

.deleteBtn {
    font-family: Maitree !important;
    background-color: #c94d32 !important;
    color: #FFF !important;
    text-transform: none !important;
    width: 130px !important;
    margin-left: 20px !important;
    font-size: 14px !important;
}

.deleteBtn:hover {
    background-color: #c94d32 !important;
    color: #FFF !important;
}

@media (min-width: 400px) {
    .cancelBtn {
        font-size: 18px !important;
    }
    .deleteBtn {
        width: 165px !important;
        font-size: 18px !important;
    }
}

.textInfo {
    color: #000 !important;
    font-size: 16px !important;
    margin-bottom: 15px !important;
}

.title {
    font-size: 20px !important;
    text-align: center !important;
    font-weight: bold !important;
}