.btnContainer {
    text-align: center;
}

.registerContainer {
    margin: 0 auto;
    max-width: 480px;
    position: relative;
}

.registerText {
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
}

.registerTitle {
    text-align: center;
    font-size: 26px;
    color: #604585;
    font-weight: bold;
    font-family: Maitree;
}

.recoverAccountBtn {
    color: #c94d32 !important;
    text-decoration: underline !important;
    position: relative !important;
    bottom: 110px !important;
}

.recoverAccountBtn:hover {
    background-color: transparent !important;
}

.recoverAccountContainer {
    text-align: center;
}

.loginBtn {
    color: #c94d32 !important;
    text-decoration: underline !important;
    font-size: 18px !important;
}

.loginBtn:hover {
    background-color: transparent !important;
}