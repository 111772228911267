.btnContainer {
	text-align: center;
}

.loadContainer {
	position: relative;
	top: 50px;
}

.login {
	margin: 0 auto;
	max-width: 480px;
	font-size: 14px;
}

.login form {
	margin: 0 auto;
	max-width: 471px;
}

.recoverAccountBtn {
    color: #c94d32 !important;
    text-decoration: underline !important;
    position: relative !important;
	font-size: 16px;
}

.recoverAccountBtn:hover {
    background-color: transparent !important;
}



