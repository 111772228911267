/* @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');



html {
  font-family: 'Noto Sans JP', sans-serif;
}



button {
  font: inherit;
  cursor: pointer;
  padding: 0.5rem 1.5rem;
  border-radius: 6px;
  background-color: transparent;
  color: #1a8ed1;
  border: 1px solid #1a8ed1;
}

button:hover,
button:active {
  background-color: #1ac5d1;
  border-color: #1ac5d1;
  color: white;
} */

* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

.vjs-control-bar {
  bottom: -30px !important;
  z-index: 99 !important;
}