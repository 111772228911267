.textFieldPhone {
    margin: 10px auto;
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 3px;
    height: 55px;
    padding: 9px 0;
}

.textField {
    width: 100%;
    margin: 10px 0;
}