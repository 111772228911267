.btnsContainer {
    text-align: center;
    margin-bottom: 40px;
}

.cancelBtn {
    background-color: #828282 !important;
    color: #FFF !important;
    text-transform: none !important;
    width: 115px !important;
    font-size: 18px !important;
    font-family: Maitree !important;
}

.cancelBtn:hover {
    background-color: #828282 !important;
    color: #FFF !important;
}

.changePlanBtn {
    background-color: #c94d32 !important;
    color: #FFF !important;
    text-transform: none !important;
    width: 165px !important;
    margin-left: 20px !important;
    font-size: 18px !important;
    font-family: Maitree !important;
}

.changePlanBtn:hover {
    background-color: #c94d32 !important;
    color: #FFF !important;
}

.subtextInfo {
    color: #000 !important;
    font-size: 18px !important;
    margin-bottom: 15px !important;
    font-family: 'Roboto', sans-serif !important;
}

.textInfo {
    color: #000 !important;
    font-size: 18px !important;
    font-family: 'Roboto', sans-serif !important;
}

.title {
    text-align: center;
    font-weight: bold !important;
    font-size: 20px !important;
}