.date {
    margin-top: 10px !important;
    width: 50%;
    border-top-right-radius: 0 !important;
}

.date fieldset {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
}

.form_group {
    width: 80%;
    margin: 20px auto;
}

.form_EditGroup {
    width: 100%;
    margin: 20px auto;
}

.control_label {
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 0;
}

.control_sublabel {
    font-size: 16px;
    color: #828282;
    font-family: 'Roboto', sans-serif;
    font-weight: lighter;
    margin: 5px auto 10px
}

.form_control {
    margin-top: 5px !important;
    width: 100%;
}

.inputModificated {
    position: absolute !important;
    bottom: 0px;
    width: 40%;
    right: 0;
    margin-right: 10% !important;
}

.inputModificated fieldset {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
}

.inputModificated input::placeholder {
    font-size: 15px;
    font-style: italic;
}

.inputModificatedEdit {
    position: absolute !important;
    bottom: 0px;
    width: 50%;
    right: 0;
    margin-right: 0% !important;
}

.inputModificatedEdit fieldset {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
}