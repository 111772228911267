.altarContainer {
	margin: 0 auto;
	max-width: 480px;
	padding-top: 1%;
	font-size: 14px;
    text-align: center;
}

.complementaryInfo {
    width: 200px;
}

.createTributBtn {
    background-color: #c94d32 !important;
    color: #FFF !important;
    text-transform: none !important;
    font-size: 18px !important;
    margin: 0 15px 15px !important;
    text-decoration: none;
    font-family: Maitree !important;
    width: 210px;
}

.item {
    font-size: 16px;
    color: #000 !important;
    margin: 0;
    text-align: left;
    text-transform: none !important;
}

.itemTitle {
    width: 80%;
    float: left;
    font-weight: bold;
}

.noUserContainer {
    text-align: center;
}

.noUserIcon {
    width: 147px !important;
    height: 147px !important;
    color: #828282 !important;
}

.title {
	color: #604585;
	text-align: center;
	font-size: 24px;
	font-family: Maitree;
}

.tributAvatar {
    width: 147px;
    height: 147px;
    margin: 0 auto;
}

.tributContainer {
    width: 323px !important;
    margin: 10px auto !important;
    padding: 0 6px 6px 9px !important;
    border-radius: 3px !important;
    border: solid 2px #604585 !important;
    font-family: 'Roboto', sans-serif !important;
    height: 309px !important;
    overflow: hidden;
}

.tributContainer:hover {
    background-color: transparent !important;
}

.tributImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.tributName {
    font-size: 16px;
    color: #604585 !important;
    margin-top: 0;
    text-transform: none !important;
}