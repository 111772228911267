.tabBtn {
	min-width: 25% !important;
	margin: 15px 5% 15px 3% !important;
	color: #000000 !important;
	padding: 10px 3px !important;
	min-height: 20px !important;
	max-height: 40px !important;
	font-family: Maitree !important;
	font-size: 16px !important;
}

.tabBtnSelected {
	font-family: Maitree !important;
	min-width: 25% !important;
	background-color: #c94d32 !important;
	margin: 15px 5% 15px 3% !important;
	color: #FFFFFF !important;
	padding: 10px 3px !important;
	min-height: 20px !important;
	max-height: 40px !important;
	border-radius: 5px !important;
	font-size: 16px !important;
}

@media (min-width: 470px) {
	.tabBtn {
		min-width: 25% !important;
		margin: 10px 5% 10px 3% !important;
		color: #000000 !important;
		padding: 10px 6px !important;
		min-height: 20px !important;
		max-height: 40px !important;
	}
	
	.tabBtnSelected {
		min-width: 25% !important;
		background-color: #c94d32 !important;
		margin: 10px 5% 10px 3% !important;
		color: #FFFFFF !important;
		padding: 10px 6px !important;
		min-height: 20px !important;
		max-height: 40px !important;
		/* border: 1px solid black !important; */
		border-radius: 5px !important;
	}
}