.itemComplementaryInfo {
    width: 50%;
    float: left;
    text-align: center;
    font-family: 'Roboto', sans-serif;
}

.itemTitle {
    width: 100%;
    display: block;
}

.itemText {
    width: 100%;
    display: block;
    margin-top: 10px;
}