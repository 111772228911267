.actionBtn {
    color: #828282 !important;
    margin-left: 10px !important;
    text-transform: none !important;
    margin: 4px 0 !important;
    font-size: 12px !important;
    font-weight: normal !important;
    width: 103px;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

@media(min-width: 480px) {
	.actionBtn {
		font-size: 14px !important;
    font-weight: normal !important;
    width: 120px;
	}
}

.actionBtn:hover {
    background-color: transparent !important;
    color: #604585 !important;
}

.actionsContainer {
    /* border-top: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8; */
    text-align: center;
    margin-top: 0px;
    position: relative;
    margin-bottom: 40px;
}

.actionIcon {
    width: 25px !important;
    height: 25px !important;
}

.actionText {
    margin-left: 10px;
    font-weight: lighter;
    position: relative;
    top: 1px;
}

.commentsText {
    color: #000000 !important;
    margin-left: 10px !important;
    text-transform: none !important;
    text-decoration: underline !important;
    float: right;
    font-size: 14px !important;
    font-family: 'Roboto', sans-serif !important;
    padding-top: 0 !important;
    margin-top: 0 !important;
}

.commentsText:hover {
    background-color: transparent !important;
}

.complementaryInfo {
    overflow: hidden;
    width: 90%;
    margin: 30px auto 0;
}

.icon {
    margin-right: 10px;
}

.inputsContainer {
    position: absolute;
    width: 100%;
    bottom: -10px;
    opacity: 0;
}

.likeIcon {
    width: 20px !important;
    height: 20px !important;
}

.likesNumber {
    position: relative;
    top: -5px;
    margin-left: 5px;
}

.likesText {
    font-size: 14px;
    float: left;
    color: #828282;
    font-family: 'Roboto', sans-serif;
    margin-top: 7px;
}

.mamoryActionsContainer {
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: 100px;
}

.mamoryActionsNoShow {
    display: none;
}

.messageContainer {
    position: absolute;
    top: 20px;
    width: 225px;
    background-color: #828282;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    color: #fff;
    right: 95px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    padding: 5px 0 5px 30px;
    text-align: left;
}

.messageIcon {
    position: absolute;
    left: 5px;
    width: 20px;
    height: 20px;
    top: 3px
}