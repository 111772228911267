.deleteMemoryBtn {
    position: absolute !important;
    right: 0;
    color: #fff !important;
    z-index: 999 !important;
    margin-top: 15px !important;
}

.deleteMemoryBtn:hover {
    background-color: transparent !important;
}

.icon {
	width: 25px !important;
	height: 25px !important;
}