.adminPlanContainer {
    font-family: 'Roboto', sans-serif;
	overflow: hidden;
}

.subsection {
	margin: 30px 20px;
}

.icon {
	color: #c94d32;
	font-size: 25px;
	float: left;
	margin-right: 10px;
}

.infoContainer {
	width: 100%;
}

@media(min-width: 1220px) {
	.infoContainer {
		float: left;
		width: 50%;
	}
}

.item {
	font-size: 18px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	width: 100%;
	display: block;
	padding-left: 35px;
}

@media(min-width: 1220px) {
	.item {
		padding-left: 40px;
		width: 84%;
	}
}

.logo {
	width: 100%;
	height: 100%;
}

.plan__image {
	width: 390px;
	height: 260px;
	margin: 0 auto;
}

@media(min-width: 1220px) {
	.plan__image {
		width: 428px;
		height: 280px;
		float: left;
	}
}

.planContainer {
	width: 100%;
	margin: 50px auto;
	overflow: hidden;
	padding-bottom: 55px;
	border-bottom: 1px solid #cdcdcd;
}

@media(min-width: 1220px) {
	.planContainer {
        width: 70%;
    }
}

.subtitle {
	font-size: 24px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #fff;
	background-color: #604585;
	padding-left: 10px;
	margin: 0 20px;
}

.text {
	font-size: 18px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #000;
	text-align: center;
}

@media(min-width: 1220px) {
	.text {
		text-align: left;
	}
}

.title {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000;
    text-align: center;
    margin-bottom: 35px;
}
/* .adminPlan {
	margin: 0 auto 50px;
	max-width: 480px;
	padding-top: 1%;
}

.planAnual__image {
	width: 90%;
	margin: 0 auto;
}

.planContainer {
	width: 90%;
	margin: 0 auto;
}

.title {
	font-size: 30px;
	text-align: center;
}

.subtitle {
	font-size: 20px;
	text-align: center;
}


.icon {
	display: block;
	text-align: center;
	margin: 0px;
	width: 130px;
} */