.bioSpecificDataContainer {
	margin: 0 auto 20px;
	width: 100%;
}

.bioSpecificData__title {
	font-family: 'Roboto', sans-serif;
	font-size: 18px;
	margin: 0;
}

.bioSpecificData__text {
	font-family: 'Roboto', sans-serif;
	padding-left: 35px;
	margin: 0;
}

.bioSpecificData__title::before {
	content: "\2022";
	color: #000;
	font-size: 50px;
	position: relative;
	top: 10px;
	margin-right: 10px;
	margin-left: -9px;
}

.deleteBtn {
	position: absolute !important;
	right: 0;
	top: 27px;
	color: #c94d32 !important;
}

.eventContainer {
	position: relative;
}

.infoText {
	width: 80%;
	display: inline-grid;
	font-weight: bold;
}

.ocurrenceContainer {
	width: 100%;
	margin: 0 auto;
	overflow: hidden;
	position: relative;
}

.ocurrenceSpecificDataContainer {
	margin: 0;
	width: 80%;
	border-left: 2px solid #c94d32;
	float: left;
}

.ocurrenceText {
	font-family: 'Roboto', sans-serif;
	padding-left: 20px;
	margin: 5px 0;
	font-size: 16px;
}

.ocurrenceTitle {
	font-family: 'Roboto', sans-serif;
	font-size: 18px;
	margin: 0;
	font-weight: bold;
}

.ocurrenceTitle::before {
	content: "\2022";
	color: #c94d32;
	font-size: 50px;
	position: relative;
	top: 10px;
	margin-right: 10px;
	margin-left: -10px;
}

.ocurrenceYearsContainer {
	width: 15%;
	float: left;
	position: relative;
	top: 12px;
	font-size: 16px;
	font-family: 'Roboto', sans-serif;
	margin-right: 5%;
	text-align: center;
}

.yearText {
	font-weight: bold;
	margin-bottom: 5px;
}