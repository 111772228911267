.acceptBtn {
	border-radius: 8px !important;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
	background-color: #c94d32 !important;
	text-transform: none !important;
	color: #fff !important;
	font-size: 18px !important;
	font-family: Maitree !important;
	width: 155px;
	float: right;
	margin-right: 20px !important;
}

.acceptBtn:hover {
	background-color: #c94d32 !important;
}

.btnsContainer {
	margin: 20px 0;
	overflow: hidden;
	padding: 10px 0;
}

.cancelBtn {
	border-radius: 8px !important;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
	background-color: #828282 !important;
	text-transform: none !important;
	color: #fff !important;
	font-size: 18px !important;
	font-family: Maitree !important;
	width: 155px;
	float: left;
	margin-left: 20px !important;
}

.cancelBtn:hover {
	background-color: #828282 !important;
	color: #fff !important;
}

.description {
    font-size: 18px;
    line-height: 1.39;
    letter-spacing: normal;
    text-align: justify;
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    overflow: hidden;
}

.messageText {
	font-size: 16px;
	text-align: center;
	font-family: 'Roboto', sans-serif;
}

.recordContainer {
    margin-top: 10px;
    font-family: 'Roboto', sans-serif;
}

.returnBtn {
    font-size: 12px !important;
    color: #000 !important;
    float: right !important;
    text-transform: none !important;
    margin-right: 5px;
}

.returnBtn:hover {
    background-color: transparent !important;
}

.title {
    font-size: 18px;
    font-weight: bold;
    color: #604585;
    float: left;
    margin-left: 10px;
    margin-top: 10px;
}