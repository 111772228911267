.containerBtns {
    text-align: center;
}

.helpBtn {
    text-transform: none !important;
    color: #890f20 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    font-size: 16px !important;
}

.recoverAccountCodeText {
    text-align: left;
    font-family: 'Roboto', sans-serif;
    margin: 0 5%;
}

.recoverAccountFirstText {
    width: 85%;
    margin: 10px 5% 35px;
    text-align: left;
    font-family: 'Roboto', sans-serif;
}

.recoverAccountText {
    width: 80%;
    margin: 10px 10% 20px;
    text-align: center;
    font-family: 'Roboto', sans-serif;
}

.resendCode {
    text-decoration: underline !important;
    color: #c94d32 !important;
    text-transform: none !important;
    width: 130px;
    margin-left: 5% !important;
}

.resendCode:hover {
    background-color: transparent !important;
}

.sendBtn {
    background-color: #c94d32 !important;
    color: #FFFFFF !important;
    width: 140px;
    height: 40px;
    margin: 30px 20px !important;
    font-family: Maitree !important;
    text-transform: none !important;
    font-size: 18px !important;
}