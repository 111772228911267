.btnContainer {
    text-align: center;
}

.createAccountBtn {
    color: #FFFFFF !important;
    border: #604585 1px solid !important;
    background-color: #604585 !important;
    cursor: pointer !important;
    box-shadow: 6px 5px 10px -3px rgba(0,0,0,0.75) !important;
    -webkit-box-shadow: 6px 5px 10px -3px rgba(0,0,0,0.75) !important;
    -moz-box-shadow: 6px 5px 10px -3px rgba(0,0,0,0.75) !important;
    border-radius: 5px !important;
    padding: 10px !important;
    font-family: Maitree !important;
    height: 50px !important;
    font-size: 18px !important;
    margin-top: 40px !important;
    text-transform: none !important;
}

.infoText {
    width: 70%;
    margin: 40px auto 10px;
    font-family: 'Roboto', sans-serif;
}

.loginContainer {
    margin: 0 auto;
    max-width: 480px;
    position: relative;
    overflow: hidden;
}

.loginText {
    text-align: center;
    font-family: 'Roboto', sans-serif;
}

.loginTitle {
    text-align: center;
    font-size: 24px;
    color: #604585;
    font-weight: bold;
    font-family: Maitree;
}

.registerContainer {
    border-top: 1px solid #979797;
    text-align: center;
}

.registerBtn {
    color: #c94d32 !important;
    text-decoration: underline !important;
}

.registerBtn:hover {
    background-color: transparent !important;
}