.addCondolenceText {
	font-size: 20px;
	font-weight: bold;
	text-align: center;
	font-family: 'Roboto', sans-serif;
	width: 80%;
	margin: 35px auto;
}

.btn {
	margin-bottom: 0;
	font-weight: 400;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	touch-action: manipulation;
	user-select: none;
	background-image: none;
	border: 1px solid transparent;
}

.btn-block {
	display: block;
	width: 100%;
}

.btn-default {
	color: #333;
	background-color: #fff;
	border-color: #ccc;
}

.btn-lg {
	padding: 10px 16px;
	font-size: 18px;
	line-height: 1.3333333;
	border-radius: 6px;
}

.control-label {
	max-width: 100%;
	margin-bottom: 5px;
	font-weight: 700;
	font-size: 16px;
}

.condolence {
	margin: 0 auto;
	max-width: 480px;
	font-size: 14px;
}

.condolenceContainer {
	position: relative;
}

.condolence form {
	margin: 0 auto;
	max-width: 480px;
}

.control-label {
	max-width: 100%;
	margin-bottom: 5px;
	font-weight: 700;
	font-size: 16px;
}

.dedicationText {
	letter-spacing: normal;
	font-family: Maitree;
	width: 100%;
	margin: 25px auto;
	font-size: 18px;
	line-height: 30px;
	text-align: justify;
}

.form-group {
	margin-bottom: 15px;
}

.form-control {
	display: block;
	width: 100%;
	height: 34px;
	padding: 6px 12px;
	font-size: 16px;
	line-height: 1.42857143;
	border: 1px solid #ccc;
}


.icon {
	display: block;
	text-align: center;
	margin: 0px;
	width: 130px;
}

.mortuaryDataBtn {
	text-align: center;
	text-transform: none !important;
	color: #000000 !important;
	text-decoration:  underline !important;
	font-size:  18px !important;
	font-weight: bold !important;
}

.mortuaryDataBtn:hover {
	background-color: transparent !important;
}

.mortuaryBtncontainer {
	text-align: center;
	margin: 0 auto;
	padding: 20px 0 30px;
	border-bottom: 4px solid #604585;
}

.number {
	font-weight: bold;
	color: #604585;
	text-decoration: underline;
}

.totalCondolences {
	font-size: 18px;
	font-family: 'Roboto', sans-serif;
	margin-left: 20px;
	margin-bottom: 45px;
}