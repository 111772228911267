.inTributBanner {
	background-image: url(https://d3cohyhv4rpctw.cloudfront.net/banner.jpg);
	width: 100%;
	height: 310px;
	background-size: cover;
	background-repeat: no-repeat;
	text-align: center;
	position: relative;
	display: flex;
	justify-content: center;
}
.inTributBanner .inTribut__btn {
	width: 210px;
	color: #FFFFFF;
	background-color: #c94d32;
	padding: 10px 20px;
	text-decoration: none;
	font-size: 18px;
	font-family: Maitree;
	display: block;
	margin: 55px auto;
	position: absolute;
	bottom: 0;
}
.inTributBanner .inTribut__title {
	color: #fff;
	margin: 0 5%;
	width: 90%;
	padding-top: 5vh;
	font-family: Maitree;
	font-size: 22px;
	font-weight: lighter;
	line-height: 1.38;
	text-align: left;
}

/* @media (min-width: 720px) {
	.inTributBanner .inTribut__title {
		margin: 0 15%;
		width: 70%;
		font-size: 24px;
	}
} */

/* @media (min-width: 1220px) {
	.inTributBanner .inTribut__title {
		margin: 0 10% 0 30%;
		width: 60%;
		font-size: 34px;
	}
} */

.searchText  {
	text-align: center;
	font-size: 18px;
	font-family: 'Roboto', sans-serif;
	margin: 0 auto 20px;
}

.searchUserContainer {
	background-color: #eee;
	padding: 32px 0;
}
.searchUserContainer .searchUser__input {
	width: 90%;
	margin: 0 5%;
	background-color: #fff;
}

/* @media (min-width: 1220px) {
	.searchUserContainer .searchUser__input {
		width: 30%;
		margin: 0 35%;
	}
} */

.searchUserContainer .searchUser__input .inputModificated {
	background-color: #fff;
	border-radius: 30px;
	width: 100%;
	font-family: 'Roboto', sans-serif;
  	font-size: 18px;
}

.searchUserContainer .seeExample__btn {
	color: #FFFFFF;
	background-color: #c94d32;
	padding: 7px 20px;
	text-decoration: none;
	font-size: 16px;
	font-family: 'Roboto', sans-serif;
	/* border: 1px solid #000; */
	margin: 40px auto 0;
	width: 210px;
	display: block;
	text-align: center;
}

.searchIcon {
	color: #604585;
}

.tributAvatar {
	margin: 0 10px 0 0;
	float: left;
	width: 45px;
	height: 45px;
}

.img {
	width: 45px;
	height: 45px;
	border-radius: 50%;
}

.tributsToSelect {
	width: 90% !important;
	margin: 0 5% !important;
}

.tributsToSelectContainer {
	width: 90% !important;
	margin: 0 5% !important;
	z-index: -999;
	position: absolute;
	top: -20px;
}

.inTribut__name {
	font-family: Maitree;
	font-size: 18px;
	margin-top: 20px;
	float: right;
}

.optionContainer {
	position: relative;
	top: -35px;
	width: 90%;
	margin: 0 5%;
}


