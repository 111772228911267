.item {
    border-bottom: 1px solid #eeeeee;
    padding: 10px 0;
    height: 50px;
}

.itemConcept {
    font-weight: bold;
    width: 50%;
    float: left;
}

.itemDescription {
    width: 50%;
    float: left;
    text-align: right;
}

.tributContainer {
    margin: 20px 0;
    border: 1px solid #eeeeee;
    border-radius: 5px;
    padding: 10px;
    font-family: 'Roboto', sans-serif;
}

.tributsContainer {
    width: 90%;
    margin: 0 auto;
}