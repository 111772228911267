.descriptionBtn {
    position: relative;
    top: 25px;
}

.iconBtn {
    position: absolute;
}

.item {
    font-size: 16px;
}

.itemTitle {
    width: 60%;
    float: left;
    font-weight: bold;
}

.moreOptionsContainer {
    text-align: center;
}

.noUserContainer {
    text-align: center;
}

.noUserIcon {
    width: 142px !important;
    height: 142px !important;
    color: #828282 !important;
}

.tributAvatar {
    width: 142px;
    height: 142px;
    margin: 0 auto;
}

.tributContainer {
    width: 252px;
    margin: 10px auto;
    padding: 12px 6px 28px 9px;
    border-radius: 3px;
    border: solid 2px #604585;
    font-family: 'Roboto', sans-serif;
}

.tributImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.tributName {
    text-align: center;
    color: #604585;
    font-size: 16px;
}

.tributOptionBtn {
    text-transform: none !important;
    color: #c94d32 !important;
    width: 33%;
    position: relative;
}

.tributOptionBtn:hover {
    background-color: transparent !important;
}

.tributrecoverBtn {
    text-transform: none !important;
    color: #c94d32 !important;
    width: 65%;
    padding: 5px !important;
    background: #f0f0f0 !important;
    border-radius: 0 !important;
    text-decoration: underline !important;
    margin-top:  18px !important;
}