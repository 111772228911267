.addIcon {
	font-size: 30px !important;
	position: relative;
	left: -10px;
}

.admin {
	margin: 0 auto;
	max-width: 480px;
	padding-top: 1%;
	font-size: 14px;
}

.btnContainer {
	text-align: center;
}

.btnHome {
	background-color: #c94d32 !important;
	color: #FFFFFF !important;
	font-size: 18px !important;
	width: 200px;
	text-transform: none !important;
}

.btnHomeContainer {
	margin-top: 35px;
	text-align: center;
	margin-bottom: 20px;
}

.complementaryText {
	font-family: 'Roboto', sans-serif;
	width: 90%;
	margin: 20px auto;
}

.createTributBtn {
	background-color: #c94d32 !important;
	font-family: Maitree !important;
	color: #FFFFFF !important;
	margin-left: 5% !important;
	margin-bottom: 15px !important;
	height: 45px;
	text-transform: none !important;
	width: 235px;
	font-size: 18px !important;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.createTributBtn:hover {
	background-color: #c94d32 !important;
	color: #FFFFFF !important;
}

.title {
	color: #604585;
	text-align: center;
	font-size: 24px;
	font-family: Maitree;
}

.subtitle {
	text-align: left;
	font-size: 18px;
	font-family: 'Roboto', sans-serif;
	width: 90%;
	margin: 20px auto;
}

