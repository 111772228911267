.cardImage {
    width: 44px;
    height: 28px;
    margin: 5px 0 0 0;
    float: left;
}

.cardNumber {
    margin-left: 20px;
}

.cardSubtext {
    color: #5b6364;
    font-size: 14px;
    margin: 0;
}

.cardText {
    font-size: 14px;
    margin: 10px 0 0;
    color: #000;
    font-family: 'Roboto', sans-serif;
}

.generalCardData {
    float: left;
    margin-left: 15px;
}

.hideNumbersCard {
    position: relative;
    top: 5px;
    margin-left: 30px;
    font-size: 16px;
}

.img {
    width: 100%;
    height: 100%;
}

.section {
    width: 100%;
    overflow: hidden;
    margin: 15px 0 0;
    position: relative;
}

.title {
    font-size: 18px;
    font-weight: bold;
    margin: 0 0 5px 0;
}