.admin {
	margin: 0 auto;
	max-width: 480px;
	padding-top: 1%;
	font-size: 14px;
}

.complementaryText {
	font-family: 'Roboto', sans-serif;
	width: 90%;
	margin: 20px auto;
}

.title {
	color: #604585;
	text-align: center;
	font-size: 24px;
	font-family: Maitree;
}