.acceptBtn {
	border-radius: 8px !important;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
	background-color: #c94d32 !important;
	text-transform: none !important;
	color: #fff !important;
	font-size: 18px !important;
	font-family: Maitree !important;
	width: 155px;
	float: right;
	margin-right: 20px !important;
}

.acceptBtn:hover {
	background-color: #c94d32 !important;
}

.addCondolenceContainer {
	overflow: hidden;
	margin-top: 5px;
    position: relative;
}

.btnsContainer {
	margin: 20px 0;
	overflow: hidden;
	padding: 10px 0;
}

.bottomSecction {
	width: 100%;
	border-top: 1px solid #a7a7a7;
}

.cancelBtn {
	border-radius: 8px !important;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
	background-color: #828282 !important;
	text-transform: none !important;
	color: #fff !important;
	font-size: 18px !important;
	font-family: Maitree !important;
	width: 155px;
	float: left;
	margin-left: 20px !important;
}

.cancelBtn:hover {
	background-color: #828282 !important;
	color: #fff !important;
}

.changeViewBtnContainer {
	text-align: center;
}

.changeViewBtn {
    width: 200px !important;
    color: #c94d32 !important;
    text-decoration: underline !important;
}

.closeModalBtn {
    position: absolute !important;
    right: 0;
    top: 0;
    color: #000 !important;
    font-size: 30px !important;
}

.closeModalBtn:hover {
    background: transparent !important;
}

.closeModalIcon {
    font-size: 30px !important;
}

.commentImageContainer {
	margin: 15px 0 0 0;
	width: 100%;
}

.deleteMedia {
	color: #000 !important;
	text-transform: none !important;
	float: right !important;
	padding: 10px;
}

.deleteMedia:hover {
	background-color: transparent !important;
}

.deleteMediaContainer {
	margin: 15px 10px 0;
	position: relative;
	width: 95%;
	text-align: right;
	overflow: hidden;
}

.formContainer {
	width: 300px;
	background: #eaeaea;
	position: relative;
	top: -10px;
}

.formContainerBig {
	width: 100%;
	background: #eaeaea;
	position: relative;
	top: -10px;
}

.icon {
	color: #604585;
}

.img {
	object-fit: cover;
	object-position: center;
	border-radius: 50%;
	width: 100%;
	height: 100%;
}

.imgContainer {
	object-fit: scale-down;
	height: 100%;
	width: 100%;
}

.messageInput {
	width: 100%;
	height: 30px;
	/* background-color: transparent !important;
	height: 20px;
	border: none !important; */
}

.messageInput fieldset {
    border: 0 !important;
}

.messageInputBig {
	width: 100%;
	/* background-color: transparent !important;
	height: 20px;
	border: none !important; */
}

.messageInputBig fieldset {
    border: 0 !important;
}

.noUserContainer {
    text-align: center;
}

.noUserIcon {
    width: 30px;
	height: 30px;
	margin: 5px 13px 13px;
	float: left;
    color: #828282 !important;
}

.rightSection {
	border-radius: 16px;
  	background-color: #eaeaea;
	overflow-y: scroll;
	min-height: 30px;
	/* margin: 21px 9px 28px 5px;
  	padding: 6px 18px 6px 19px; */
}

.subtitle {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    text-align: center;
}

.topSecction {
	width: 100%;
	overflow: hidden;
	margin-bottom: 10px;
}

.userImage {
	width: 30px;
	height: 30px;
	margin: 0 13px 13px;
	float: left;
}

.video {
	width: 100%;
	height: 100%;
	margin: 0;
}

.videoContainer {
	height: 250px;
	padding-bottom: 20px;
	margin-top: 15px;
}