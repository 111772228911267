.changeViewBtnContainer {
	text-align: center;
}

.changeViewBtn {
    width: 200px !important;
    color: #c94d32 !important;
    text-decoration: underline !important;
}

.closeModalBtn {
    position: absolute !important;
    right: 0;
    top: 0;
    color: #000 !important;
    font-size: 30px !important;
}

.closeModalBtn:hover {
    background: transparent !important;
}

.closeModalIcon {
    font-size: 30px !important;
}

.followTributeBtn {
    background-color: #c94d32 !important;
    color: #FFF !important;
    text-transform: none !important;
    width: 165px !important;
    font-size: 18px !important;
	margin: 10px 0 15px !important;
}

.followTributeBtn:hover {
    background-color: #c94d32 !important;
    color: #FFF !important;
}

.img {
	object-fit:cover;
    object-position: center;
	border-radius: 50%;
    width: 100%;
    height: 100%;
}

.myTributAvatar {
	height: 180px;
	margin: 20px auto 10px;
	width: 180px;
}

.myTributDetails {
	color: #FFFFFF;
	text-align: center;
}

.myTributDetails__date {
	color: #B5B5B5;
	font-size: 18px;
}

.myTributDetails__name {
	margin: 0 0 10px 0;
	font-size: 18px;
}

.myTributDetails__text {
	color: #B5B5B5;
	font-size: 16px;
	font-family: Maitree;
	margin-top: 0;
	padding-top: 10px;
}

.myTributImageContainer {
	background-image: url(https://d3cohyhv4rpctw.cloudfront.net/banner.jpg);
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    position: relative;
	overflow: hidden;
}

.noUserContainer {
    text-align: center;
}

.noUserIcon {
    width: 180px !important;
    height: 180px !important;
    color: #828282 !important;
}

.shareBtn {
	position: absolute !important;
	right: 0;
	z-index: 99;
	color: #FFFFFF !important;
}

.shareInput {
	opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
}

.subtitle {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    text-align: center;
}

.unfollowTributeBtn {
	background-color: #929292 !important;
    color: #FFF !important;
    text-transform: none !important;
    width: 165px !important;
    font-size: 18px !important;
	margin: 10px 0 15px !important;
}

.unfollowTributeBtn:hover {
    background-color: #929292 !important;
    color: #FFF !important;
}