.btnsContainer {
    text-align: center;
    margin-bottom: 35px;
}

.chargesBtn {
    background-color: #c94d32 !important;
    text-transform: none !important;
    color: #fff !important;
    width: 342px !important;
}

.complementaryInfo {
    margin: 0;
}

.helpBtn {
    text-transform: none !important;
    color: #890f20 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    font-size: 18px !important;
    font-weight: normal !important;
    text-decoration: underline !important;
}

.img {
    width: 100%;
    height: 100%;
}

.infoContainer {
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    width: 340px;
    margin: 0 auto;
}

.inTributICon {
    margin: 0 auto;
    height: 42px;
    width: 202px;
}

.noUserIcon {
    width: 142px !important;
    height: 142px !important;
    color: #828282 !important;
}

.returnBtn {
    background-color: #c94d32 !important;
    text-transform: none !important;
    color: #fff !important;
    width: 193px !important;
}

.sendMessgeBtn {
    background-color: #c94d32 !important;
    text-transform: none !important;
    color: #fff !important;
    width: 273px !important;
}

.sorryText {
    font-weight: bold;
}

.tributAvatar {
    width: 142px;
    height: 142px;
    margin: 0 auto;
}

.tributImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
}

.userName {
    font-family: Maitree !important;
    font-weight: bold;
}

.warningMessage {
    width: 100%;
    background: #ba0909;
    color: #fff;
    padding: 10px 0;
    font-size: 22px;
}