.addCardContainer {
    padding: 20px;
    overflow: hidden;
	font-family: 'Roboto', sans-serif;
    max-width: 480px;
    margin: 35px auto;
}

.dataContainer {
    background-color: #f0f0f0;
    padding: 20px;
    overflow: hidden;
	font-family: 'Roboto', sans-serif;
    max-width: 480px;
    margin: 35px auto;
}

.deleteCard {
    color: #c94d32 !important;
    font-size: 16px !important;
    padding-left: 0 !important;
    text-decoration: underline !important;
}

.deleteCard:hover {
    background: transparent !important;
}

.changeCard {
    color: #5b6364 !important;
    font-size: 16px !important;
    padding-left: 0 !important;
    margin-left: 50px !important;
}

.changeCard:hover {
    background: transparent !important;
}

.newMethodBtn {
    background-color: #c94d32 !important;
	color: #FFFFFF !important;
	font-size: 15px !important;
    padding: 10px !important;
	text-transform: none !important;
    display: block !important;
    margin: 0 auto !important;
}

.newMethodBtn:hover {
    background-color: #c94d32 !important;
}

.paymentText {
    font-size: 12px;
    text-align: center;
    color: #000;
}

.section {
    width: 100%;
    overflow: hidden;
    margin: 15px 0 0;
    position: relative;
}

.title {
    font-size: 18px;
    font-weight: bold;
    margin: 0 0 5px 0;
}