.userBtnContainer {
    text-align: center;
    margin-bottom: 20px;
}
.userBtnContainer .userBtn {
    color: #FFFFFF;
    border: #c94d32 1px solid;
    background-color: #c94d32;
    cursor: pointer;
    box-shadow: 6px 5px 10px -3px rgba(0,0,0,0.75);
    -webkit-box-shadow: 6px 5px 10px -3px rgba(0,0,0,0.75);
    -moz-box-shadow: 6px 5px 10px -3px rgba(0,0,0,0.75);
    border-radius: 5px;
    padding: 10px;
    width: 180px;
    font-family: Maitree;
    width: 200px;
    height: 50px;
    font-size: 18px;
    margin-top: 20px;
}

.userBtnContainer .userBtn:disabled {
    color: #FFFFFF;
    border: #7b7a79 1px solid;
    background-color: #7b7a79;
    cursor: auto;
    box-shadow: 6px 5px 10px -3px rgba(0,0,0,0.75);
    -webkit-box-shadow: 6px 5px 10px -3px rgba(0,0,0,0.75);
    -moz-box-shadow: 6px 5px 10px -3px rgba(0,0,0,0.75);
    border-radius: 5px;
    padding: 10px;
    width: 180px;
    font-family: Maitree;
    width: 200px;
    height: 50px;
    font-size: 18px;
}

.userBtnContainerDouble {
    margin: 10px 5%;
    float: left;
    text-align: center;
    width: 40%;
}


.userBtnContainerDouble .userBtn {
    color: #FFFFFF;
    border: #c94d32 1px solid;
    background-color: #c94d32;
    cursor: pointer;
    box-shadow: 6px 5px 10px -3px rgba(0,0,0,0.75);
    -webkit-box-shadow: 6px 5px 10px -3px rgba(0,0,0,0.75);
    -moz-box-shadow: 6px 5px 10px -3px rgba(0,0,0,0.75);
    border-radius: 5px;
    padding: 5px;
    width: 95%;
    height: 50px;
    margin: 20px 0;
    font-size: 18px;
}

.userBtnContainerDouble .cancelBtn {
    color: #FFFFFF;
    background-color: #828282;
    cursor: pointer;
    box-shadow: 6px 5px 10px -3px rgba(0,0,0,0.75);
    -webkit-box-shadow: 6px 5px 10px -3px rgba(0,0,0,0.75);
    -moz-box-shadow: 6px 5px 10px -3px rgba(0,0,0,0.75);
    border-radius: 5px;
    padding: 5px;
    width: 95%;
    height: 50px;
    margin: 20px 0;
    font-size: 18px;
}

.userBtnContainerDouble .userBtn:disabled {
    color: #000000;
    border: #7b7a79 1px solid;
    background-color: #7b7a79;
    cursor: auto;
    box-shadow: 6px 5px 10px -3px rgba(0,0,0,0.75);
    -webkit-box-shadow: 6px 5px 10px -3px rgba(0,0,0,0.75);
    -moz-box-shadow: 6px 5px 10px -3px rgba(0,0,0,0.75);
    border-radius: 5px;
    padding: 5px;
    width: 95%;
    height: 50px;
    margin: 20px 0;
    font-size: 18px;
}