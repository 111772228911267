.changePlanInput {
    width: 40%;
    float: right;
    margin-right: 10% !important;
    background: white !important;
    position: absolute !important;
    top: 5px;
}

.dataContainer {
    background-color: #f0f0f0;
    padding: 20px;
    overflow: hidden;
	font-family: 'Roboto', sans-serif;
	margin-bottom: 35px;
    max-width: 480px;
    margin: 0 auto;
    position: relative;
}

.editPlanBtn {
    position: absolute !important;
    right: 0;
    color: #c94d32 !important;
    top: 0;
}

.editPlanBtn:hover {
    background-color: transparent !important;
}

.infoIcon {
    color: #c94d32 !important;
    top: 5px;
    position: relative;
    margin-left: 10px;
}

.item {
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #000;
    margin: 0 0 5px 0;
    overflow: hidden;
}

.itemData {
    width: 50%;
    float: right;
    font-weight: normal;
}

.itemTitle {
    width: 50%;
    float: left;
    font-weight: bold;
}